// Hooks
import { useRouter } from 'next/router';
import { setCookie } from 'nookies';
import React, { useEffect } from 'react';

// Contentful
import safeJsonStringify from 'safe-json-stringify';
import contentfulClient from '../client/contentful';

// Components
import Footer from '../components/Footer/Footer';
import HomeHeader from '../components/HomeHeader/HomeHeader';
import HomeCases from '../components/HomeCases/HomeCases';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import Navigation from '../components/Navigation/Navigation';
import ProductFeatures from '../components/ProductFeatures/ProductFeatures';
import ClientsOverview from '../components/ClientsOverview/ClientsOverview';
import PlaybooksGrid from '../components/PlaybooksGrid/PlaybooksGrid';
import SeoMeta from '../components/SeoMeta/SeoMeta';
import CallToAction from '../components/CallToAction/CallToAction';

// Data Utils
import getPlaybooksGridData from '../utils/data/getPlaybooksGridData';
import getProductFeaturesData from '../utils/data/getProductFeaturesData';
import getClientsOverviewData from '../utils/data/getClientsOverviewData';
import getCallToActionData from '../utils/data/getCallToActionData';
import getSeoData from '../utils/data/getSeoData';

// Utils
import useOpenGraphImage from '../utils/useOpenGraphImage';

const Home = (props) => {
  const data = props.data.fields;
  const router = useRouter();
  const productFeaturesData = data?.productFeatures?.[0] ? getProductFeaturesData(data.productFeatures[0]) : false;
  const clientsOverviewData = data?.clientsOverview ? getClientsOverviewData(data.clientsOverview) : false;
  const playBooksGridData = data?.playbooksGrid?.[0] ? getPlaybooksGridData(data.playbooksGrid[0]) : false;
  const callToActionData = data?.cta ? getCallToActionData(data.cta) : false;

  const { seoImageURL } = useOpenGraphImage('/home');
  const seoData = getSeoData(props.data.fields, seoImageURL);

  useEffect(() => {
    const path = router.asPath.split('?')[0];
    if (path === '/friends-of-mutiny' || path === '/disrupt' || path === '/producthunt') {
      setCookie(router, 'mutiny.source', path.replace('/', ''), {
        maxAge: 30 * 24 * 60 * 60,
      });
    }
  }, []);

  return (
    <>
      <SeoMeta {...seoData} />
      <PageWrapper>
        <Navigation modifier="Navigation--home" />
        <HomeHeader title={data.homeHeaderTitle} />
        <HomeCases cases={data.casesBlock} />
        {clientsOverviewData && <ClientsOverview {...clientsOverviewData} />}
        {productFeaturesData && <ProductFeatures {...productFeaturesData} />}
        {playBooksGridData && <PlaybooksGrid {...playBooksGridData} />}
        {callToActionData && <CallToAction {...callToActionData} source="homepage" />}
      </PageWrapper>
      <Footer />
    </>
  );
};

export async function getStaticProps() {
  let data = {};

  const client = contentfulClient;

  await client.getEntry('7qZNWYcET3lhbLIJmcVE4M', { include: 10 }).then((entry) => {
    data = JSON.parse(safeJsonStringify(entry));
  });
  return {
    props: { data },
  };
}

export default Home;
